import React, { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useAuthentication from "./util-use-auth";

const SinglePageGuard: FC<{ children: ReactNode }> = ({ children }) => {
  const { role } = useAuthentication();

  if (role?.viewSingleTrip) {
    return <Navigate replace to={".."} />;
  }

  return <>{children}</>;
};

export default SinglePageGuard;
