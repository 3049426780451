import { useContext } from "react";

import AuthContext from "../states/state-auth";

import { Role } from "../types/role";
import { Authentication } from "../types/user";

const useAuthentication = () => useContext(AuthContext);

type AuthenticatedUser = {
  user: Authentication;
  role: Role;
};
export const useUser = (): AuthenticatedUser => {
  const { user, role, logout } = useAuthentication();

  if (!user || !role) {
    throw logout();
  }

  return {
    user,
    role
  };
};

export default useAuthentication;
